body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background: rgb(247, 142, 35);
  background: linear-gradient(
    180deg,
    rgba(247, 142, 35, 1) 0%,
    rgba(254, 81, 42, 1) 10%,
    rgba(241, 73, 255, 1) 200%
  );
  background-size: cover;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
