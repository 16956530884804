.externalLink {
  margin: 0px;
  color: rgb(56, 58, 54);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}