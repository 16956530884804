.menuTitle {
  margin: 0px;
  color: rgb(56, 58, 54);
  font-weight: 700;
  font-size: 145%;
  line-height: 1.5;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}

.customMenuButton {
  background-color: black !important;
  border-radius: 25px !important;
}

.customMenuText {
  font-weight: 500;
  font-size: 100%;
  color: #E5E8E8;
}